import { ADD_TEMPLATE_HEADERS, DELETE_TEMPLATE_HEADERS } from '../../Validator/constants';
import { IExcelCellProps, IExcelDownloadFormat, KeyErrorNameMap } from './types';

const addOrEditFormattor = (errorData: Record<string, unknown>[]): IExcelDownloadFormat => {
    const formattedData: IExcelDownloadFormat = [];
    errorData.map((v: Record<string, unknown>) => {
        const errors = JSON.parse(v.errors as string);
        const errorKeys = Object.keys(errors);
        if (!errorKeys.length) {
            return;
        }
        const keysWithErrors: ADD_TEMPLATE_HEADERS[] = [];
        const errorStr = errorKeys.reduce((str, k) => {
            const key = KeyErrorNameMap[k];
            const value = errors[k];
            if (key !== 'Global' && key !== 'MidTerm') keysWithErrors.push(key);
            return str + `${key} - ${value} \n`;
        }, '');
        const cellProps: Record<string, IExcelCellProps> = {};
        keysWithErrors.map((k) => {
            cellProps[k] = {
                cellColour: 'DA9694'
            };
        });
        cellProps['Errors'] = {
            fontColour: 'FF0000'
        };
        formattedData.push({
            data: {
                [ADD_TEMPLATE_HEADERS.userId]: v.userId,
                [ADD_TEMPLATE_HEADERS.employeeID]: v.employee_id,
                [ADD_TEMPLATE_HEADERS.relationship]: v.relationship_to_account_holders,
                [ADD_TEMPLATE_HEADERS.name]: v.name,
                [ADD_TEMPLATE_HEADERS.slabId]: v.slabId,
                [ADD_TEMPLATE_HEADERS.mobile]: v.mobile,
                [ADD_TEMPLATE_HEADERS.email]: v.email_address,
                [ADD_TEMPLATE_HEADERS.gender]: v.gender,
                [ADD_TEMPLATE_HEADERS.ctc]: v.ctc,
                [ADD_TEMPLATE_HEADERS.enrolmentDueDate]: v.enrolment_due_date,
                [ADD_TEMPLATE_HEADERS.dateOfBirth]: v.date_of_birth,
                [ADD_TEMPLATE_HEADERS.coverageStartDate]: v.policy_start_date,
                [ADD_TEMPLATE_HEADERS.exception]: v.isException,
                Errors: errorStr || ''
            },
            props: cellProps
        });
    });
    return formattedData;
};

const offboardFormattor = (
    errors: Record<string, unknown>[]
): { data: Record<string, unknown>; props: Record<string, IExcelCellProps> }[] => {
    const colNameMap: Record<string, string> = {
        userId: DELETE_TEMPLATE_HEADERS.userId,
        relationship: DELETE_TEMPLATE_HEADERS.relationship,
        dateOfLeaving: DELETE_TEMPLATE_HEADERS.dateOfLeaving,
        error: 'Errors',
        global: 'Global'
    };
    const formattedErrors: { data: Record<string, unknown>; props: Record<string, IExcelCellProps> }[] = [];
    errors.map((v) => {
        const errors = JSON.parse(v.error as string);
        const errorKeys = Object.keys(errors);
        const keys = Object.keys(v);
        const newObj: Record<string, unknown> = {};
        keys.map((k) => {
            newObj[colNameMap[k]] = v[k];
        });
        const keysWithErrors: string[] = [];
        const errorStr = errorKeys.reduce((str, k) => {
            const key = colNameMap[k];
            const value = errors[k];
            if (key !== 'Global') keysWithErrors.push(key);
            return str + `${key} - ${value} \n`;
        }, '');
        newObj['Errors'] = errorStr;
        const cellProps: Record<string, IExcelCellProps> = {};
        keysWithErrors.map((k) => {
            cellProps[k] = {
                cellColour: 'DA9694'
            };
        });
        cellProps['Errors'] = {
            fontColour: 'DA9694'
        };
        formattedErrors.push({
            data: newObj,
            props: cellProps
        });
    });
    return formattedErrors;
};

export default {
    add: addOrEditFormattor,
    edit: addOrEditFormattor,
    offboard: offboardFormattor
};
