import { IPlacement } from './PlacementDetails';

export const slabDetails: Record<string, IPlacement[]> = {
    gmc: [
        {
            isMandatory: true,
            label: 'Slab Id',
            name: 'slabId',
            type: 'input',
            fullWidth: true,
            inputType: 'string',
            isDisabled: true,
            display: true
        },
        {
            isMandatory: true,
            label: 'Family Definition',
            name: 'familyDefinition',
            type: 'dropdown',
            fullWidth: true,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Sum Insured',
            name: 'sumInsured',
            type: 'input',
            fullWidth: false,
            inputType: 'number',
            display: true
        },
        {
            isMandatory: false,
            label: 'OPD Benefit',
            name: 'opdBenefit',
            type: 'input',
            fullWidth: true,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Room Rent Limit',
            name: 'roomRentLimit',
            type: 'input',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'ICU Room Rent',
            name: 'icuRoomRent',
            type: 'input',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Maternity Benefits Limit',
            name: 'maternityBenefitsLimit',
            type: 'input',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: false,
            label: 'Pre & Post Natal Expenses',
            name: 'preAndPostNatalExpenses',
            type: 'input',
            fullWidth: false,
            inputType: 'number',
            display: true
        },
        {
            isMandatory: false,
            label: 'Corporate Buffer',
            name: 'corporateBuffer',
            type: 'input',
            fullWidth: false,
            inputType: 'number',
            display: true
        },
        {
            isMandatory: false,
            label: 'Corporate Buffer Restriction',
            name: 'corporateBufferRestriction',
            type: 'input',
            fullWidth: true,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: false,
            label: 'Corporate Buffer Ailment Restriction',
            name: 'corporateBufferAilmentRestriction',
            type: 'input',
            fullWidth: true,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Ambulance Expense Limit',
            name: 'ambulanceCharges',
            type: 'input',
            fullWidth: true,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Co-payment, if any',
            name: 'coPayment',
            type: 'dropdown',
            fullWidth: true,
            inputType: 'string',
            display: true
        }
    ],
    gpa: [
        {
            isMandatory: true,
            label: 'Slab Id',
            name: 'slabId',
            type: 'input',
            fullWidth: true,
            inputType: 'string',
            isDisabled: true,
            display: true
        },
        {
            isMandatory: true,
            label: 'Family Definition',
            name: 'familyDefinition',
            type: 'dropdown',
            fullWidth: true,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Sum Insured',
            name: 'sumInsured',
            type: 'input',
            fullWidth: false,
            inputType: 'number',
            display: true
        },
        {
            isMandatory: true,
            label: 'Temporary Total Disability (TTD)',
            name: 'temporaryTotalDisability',
            type: 'input',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Medical Expenses',
            name: 'medicalExpenses',
            type: 'input',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Education Benefit',
            name: 'educationBenefit',
            type: 'input',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Funeral Expenses',
            name: 'funeralExpenses',
            type: 'input',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Ambulance Charges',
            name: 'ambulanceCharges',
            type: 'input',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Carriage of dead body',
            name: 'carriageOfDeadBody',
            type: 'input',
            fullWidth: false,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Repatriation of Mortal Remains',
            name: 'repatriationOfMortalRemains',
            type: 'input',
            fullWidth: false,
            inputType: 'string',
            display: true
        }
    ],
    gtl: [
        {
            isMandatory: true,
            label: 'Slab Id',
            name: 'slabId',
            type: 'input',
            fullWidth: true,
            inputType: 'string',
            isDisabled: true,
            display: true
        },
        {
            isMandatory: true,
            label: 'Sum Insured',
            name: 'sumInsured',
            type: 'input',
            fullWidth: true,
            inputType: 'number',
            display: true
        },
        {
            isMandatory: true,
            label: 'Family Definition',
            name: 'familyDefinition',
            type: 'dropdown',
            fullWidth: true,
            inputType: 'string',
            display: true
        }
    ],
    covid: [
        {
            isMandatory: true,
            label: 'Slab Id',
            name: 'slabId',
            type: 'input',
            fullWidth: true,
            inputType: 'string',
            isDisabled: true,
            display: true
        },
        {
            isMandatory: true,
            label: 'Family Definition',
            name: 'familyDefinition',
            type: 'dropdown',
            fullWidth: true,
            inputType: 'string',
            display: true
        },
        {
            isMandatory: true,
            label: 'Sum Insured',
            name: 'sumInsured',
            type: 'input',
            fullWidth: false,
            inputType: 'number',
            display: true
        },
        {
            isMandatory: false,
            label: 'Ambulance charges',
            name: 'ambulanceCharges',
            type: 'input',
            fullWidth: false,
            inputType: 'string',
            display: true
        }
    ]
};
