import React from 'react';
import { TableLoading } from '../../../../atoms';
import MemberDetailsRow from '../../../../containers/MemberDetailsRow';
import { IUserData, MemberDetailsTableBodyProps } from './types';
import { UserPoliciesModal } from '../../../../containers';
import { deleteUserAPI } from '../../../../../adapters';
import { useToast } from '../../../../hooks/useToast';
import { TableEmptyState } from '@loophealth/loop-ui-web-library';
import MemberDetailsUpdateModal from '../../../../containers/MemberDetailsUpdateModal';

const MemberDetailsTableBody: React.FunctionComponent<MemberDetailsTableBodyProps> = ({
    company,
    users,
    searchedUsers,
    searchedText
}) => {
    const toast = useToast();
    // states for handling error messages
    const [errorMsg, setErrorMsg] = React.useState('');
    const [uDetailsToUpdate, setUDetailsToUpdate] = React.useState<IUserData>();
    const [showUDetailsUpdateModal, toggleUDetailsUpdateModal] = React.useState(false);

    React.useEffect(() => {
        if (company.error) {
            setErrorMsg('Could not fetch company details');
            return;
        }
        if (users.error) {
            setErrorMsg('Could not fetch member details');
        }
        if (!users.data.length) {
            setErrorMsg('Please add atleast one user first.');
            return;
        }
        if (searchedText.length) {
            if (searchedUsers.length === 0) {
                setErrorMsg('No results matching search.');
            }
        }
        setErrorMsg('');
    }, [company, users, searchedText]);

    // states for viewing userPolicies of a member
    const [showModal, setShowModal] = React.useState(false);
    const [uPolUserId, setUPolUserId] = React.useState('');
    const showUserPoliciesModal = (userId: string) => {
        setUPolUserId(userId);
        setShowModal(true);
    };
    const showDeleteMemberModal = async (user: IUserData) => {
        const userId = user.userId;
        const name = user.firstName + ' ' + user.lastName;
        const msg = `
            Be Caustious!
            Clicking on OK will delete the Member. 
            Are you sure about deleting member - ${name}? 
        `;
        const deleteUser = window.confirm(msg);
        if (deleteUser) {
            // call delete API
            const res: any = await deleteUserAPI(userId);
            if (res.status !== 200) {
                toast?.displayToast('error', 'Failed to Delete!', res.message.toString());
            } else {
                window.location.reload();
            }
        }
    };

    const interceptVisibility = (isVisible: boolean) => {
        if (!isVisible) {
            setUPolUserId('');
        }
        setShowModal(isVisible);
    };

    let usersToDisplay = users.data;
    if (searchedText.length) {
        usersToDisplay = searchedUsers || [];
    }
    if (company.loading || users.isLoading) {
        return <TableLoading />;
    }
    const handleMemberUpdateClick = (user: IUserData) => {
        toggleUDetailsUpdateModal(true);
        setUDetailsToUpdate(user);
    };
    const handleOnSuccess = () => {
        toggleUDetailsUpdateModal(false);
    };
    return (
        <>
            <UserPoliciesModal
                isVisible={showModal}
                setIsVisible={interceptVisibility}
                userId={uPolUserId}
                companyId={company.data.id}
            />
            {(errorMsg.length && (
                <div style={{ captionSide: 'bottom', display: 'table-caption', textAlign: 'center' }}>
                    <TableEmptyState message={errorMsg} />
                </div>
            )) || <></>}
            {usersToDisplay.map((user, index) => (
                <MemberDetailsRow
                    user={user}
                    key={user.userId + index}
                    index={index}
                    showUserPolicies={showUserPoliciesModal}
                    deleteMember={showDeleteMemberModal}
                    onUpdateClick={() => handleMemberUpdateClick(user)}
                />
            ))}
            {uDetailsToUpdate &&
                <MemberDetailsUpdateModal
                    isOpen={showUDetailsUpdateModal}
                    onOpenChange={(o) => {
                        toggleUDetailsUpdateModal(o);
                        if (!o) setUDetailsToUpdate(undefined);
                    }}
                    initialValues={uDetailsToUpdate}
                    onSuccess={handleOnSuccess}
                />
            }
        </>
    );
};

export default React.memo(MemberDetailsTableBody);
