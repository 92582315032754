import { ADD_TEMPLATE_HEADERS } from '../../../pages/SPAContainer/pages/Validator/constants';

export interface IExcelSheet<T extends Record<string, unknown>> {
    sheetName: string;
    rows: IExcelRows<T>[];
}

export interface IExcelRows<T extends Record<string, unknown>> {
    data: T;
    props: Record<string, IExcelCellProps>;
}

export interface IExcelCellProps {
    cellColour?: string;
    fontColour?: string;
}

export type IExcelDownloadFormat = IExcelRows<Record<ADD_TEMPLATE_HEADERS | 'Errors', unknown>>[];

export const KeyErrorNameMap: Record<string, ADD_TEMPLATE_HEADERS | 'Global'> = {
    userId: ADD_TEMPLATE_HEADERS.userId,
    employeeId: ADD_TEMPLATE_HEADERS.employeeID,
    relationship: ADD_TEMPLATE_HEADERS.relationship,
    firstName: ADD_TEMPLATE_HEADERS.name,
    slabId: ADD_TEMPLATE_HEADERS.slabId,
    mobile: ADD_TEMPLATE_HEADERS.mobile,
    email: ADD_TEMPLATE_HEADERS.email,
    gender: ADD_TEMPLATE_HEADERS.gender,
    ctc: ADD_TEMPLATE_HEADERS.ctc,
    enrolmentDueDate: ADD_TEMPLATE_HEADERS.enrolmentDueDate,
    dob: ADD_TEMPLATE_HEADERS.dateOfBirth,
    policyStartDate: ADD_TEMPLATE_HEADERS.coverageStartDate,
    isException: ADD_TEMPLATE_HEADERS.exception,
    global: 'Global'
};

export const ErrorSheetColumnNames: (ADD_TEMPLATE_HEADERS | 'Errors')[] = [
    ADD_TEMPLATE_HEADERS.userId,
    ADD_TEMPLATE_HEADERS.employeeID,
    ADD_TEMPLATE_HEADERS.relationship,
    ADD_TEMPLATE_HEADERS.name,
    ADD_TEMPLATE_HEADERS.coverageStartDate,
    ADD_TEMPLATE_HEADERS.enrolmentDueDate,
    ADD_TEMPLATE_HEADERS.slabId,
    ADD_TEMPLATE_HEADERS.mobile,
    ADD_TEMPLATE_HEADERS.email,
    ADD_TEMPLATE_HEADERS.dateOfBirth,
    ADD_TEMPLATE_HEADERS.gender,
    ADD_TEMPLATE_HEADERS.ctc,
    ADD_TEMPLATE_HEADERS.exception,
    'Errors'
];
