import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { ReduxState } from '../../../../../redux';
import { createCompany } from '../../../../../redux/slices/CompanySlice/thunks';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '../../../../../utils/constants/Routes';
import useFormInput from '../../../../hooks/useFormInput';
import { Button, Card, ErrorContainer, LabelButton, Loader, PageHeader, UploadPill } from '../../../../atoms';
import {
    StyledCardContainer,
    StyledCompanyDetailsContainer,
    StyledConfimBtnWrapper,
    StyledContainer,
    StyledDosAndDontsItems,
    StyledErrorWrapper,
    StyledForm,
    StyledImage,
    StyledImageUploadContainer,
    StyledImgRemContainer,
    StyledNameHelperText,
    StyledNicknameGuideline,
    StyledNicknameGuidelineDivider,
    StyledNicknameGuidelineHeader,
    StyledNicknameGuidelineHeaderDosAndDontsItem,
    StyledNicknameGuidelineHeaderDosAndDonts,
    StyledNicknameGuidelineHeaderTitle,
    StyledSpacing,
    StyledUploadPillWrapper,
    StyledNotice,
    StyledAlertData,
    StyledSpacer
} from './styles';
import { Form, Typography } from '@loophealth/loop-ui-web-library';
import { FormError } from '../../../../atoms';
import { AnnouncementIcon, CloseCircleIcon, InfoIcon, TickCircleIcon } from '../../../../../assets/img';
import { resetCreateCompanyState } from '../../../../../redux/slices/CompanySlice';

const AddCompany: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const dispatch = useDispatch();
    const name = useFormInput('', true, 'name');
    const nickName = useFormInput('', true, 'nickname');
    const regdAddress = useFormInput('', false, 'address');
    const gstNumber = useFormInput('', true, 'gstin');
    const panNumber = useFormInput('', true, 'pan');
    const salesforceId = useFormInput('', true, 'salesforceId');
    const spocName = useFormInput('');
    const spocPhoneNumber = useFormInput('', false, 'phone');
    const spocEmail = useFormInput('', false, 'email');
    const createCompanyProcess = useSelector((state: ReduxState) => state.companySlice.createCompany);
    const [companyLogo, setCompanyLogo] = React.useState<{ file: Blob; url: string } | null>(null);
    const [companyLogoError, setCompanyLogoError] = React.useState('');
    const [formError, setFormError] = React.useState('');

    const uploadLogo = (files: Record<string, unknown>[]) => {
        const url = URL.createObjectURL(files[0] as unknown as Blob);
        setCompanyLogo({
            file: files[0] as unknown as Blob,
            url
        });
    };

    const navigateToCompanyDirectory = () => {
        navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.COMPANY_DIRECTORY.route);
    };

    const checkIfFormValid = () => {
        const nameV = name.finalValidationCheck();
        const nickNameV = nickName.finalValidationCheck();
        const gstNumberV = gstNumber.finalValidationCheck();
        const panNumberV = panNumber.finalValidationCheck();
        const salesforceIdV = salesforceId.finalValidationCheck();
        let phoneV = true;
        let emailV = true;
        let regdAddressV = true;
        if (spocPhoneNumber.value.length) {
            phoneV = spocPhoneNumber.finalValidationCheck();
        }
        if (spocEmail.value.length) {
            emailV = spocEmail.finalValidationCheck();
        }
        if (regdAddress.value.length) {
            regdAddressV = regdAddress.finalValidationCheck();
        }

        if (nameV && nickNameV && gstNumberV && panNumberV && salesforceIdV && phoneV && emailV && regdAddressV) {
            setCompanyLogoError('');
            setFormError('');
            return true;
        }
        setFormError('Please fix all form errors!');
        return false;
    };

    const onCreateCompany = () => {
        if (checkIfFormValid()) {
            dispatch(
                createCompany({
                    gstNumber: gstNumber.value,
                    name: name.value,
                    panNumber: panNumber.value,
                    regdAddress: regdAddress.value || '',
                    spocName: spocName.value || '',
                    spocEmail: spocEmail.value || '',
                    nickname: nickName.value,
                    salesforceId: salesforceId.value,
                    spocPhoneNumber: spocPhoneNumber.value || '',
                    logoFile: companyLogo?.file,
                    navigate: navigateToCompanyDirectory
                })
            );
        }
    };

    useEffect(() => {
        if (createCompanyProcess.error?.message.includes('Nickname')) {
            nickName.setErrorText('Company nickname already exists. Refer guidelines.');
        } else nickName.setErrorText('');
    }, [createCompanyProcess.error]);

    useEffect(() => {
        dispatch(resetCreateCompanyState());
    }, []);
    return (
        <StyledContainer>
            <PageHeader>Add Company</PageHeader>
            <StyledNotice>
                <img src={AnnouncementIcon} />
                <StyledAlertData>
                    <Typography variant='medium' weight='bold' color='emerald'>
                        New field added &apos;Company Nickname&apos;
                    </Typography>
                    <Typography variant='medium'>
                        This is a new field to simplify and distinguish company profiles on the HR dashboard.
                    </Typography>
                </StyledAlertData>
            </StyledNotice>
            <Card>
                <StyledCardContainer>
                    <StyledCompanyDetailsContainer>
                        <h3>Company Details</h3>
                        <StyledForm>
                            <Form.FormField id='name' required={true} label='Legal Name of the Company'>
                                <Form.Input
                                    name="name"
                                    error={name.errorText}
                                    placeholder="Enter company name"
                                    {...name}
                                />
                                {
                                    name.errorText
                                        ? <StyledSpacer />
                                        : <StyledNameHelperText>
                                            <Typography color='secondary' variant='small'>
                                                As per company PAN or Aadhar card
                                            </Typography>
                                        </StyledNameHelperText>
                                }
                            </Form.FormField>
                            <Form.FormField id='nickname' label='Company Nickname' required={true}>
                                <Form.Input
                                    name="nickname"
                                    placeholder="Enter Company Name"
                                    error={nickName.errorText}
                                    {...nickName}
                                />
                                {nickName.errorText && <StyledSpacer />}
                                <StyledNicknameGuideline>
                                    <StyledNicknameGuidelineHeader>
                                        <StyledNicknameGuidelineHeaderTitle>
                                            <img src={InfoIcon} alt='info icon' aria-hidden='true' />
                                            <Typography variant='small' weight='medium' color='primary'>
                                                Guidelines for a Good Company Nickname
                                            </Typography>
                                        </StyledNicknameGuidelineHeaderTitle>
                                        <Typography variant='extraSmall' color='secondary'>
                                            This nickname shows up on the HRD, so make it count!
                                        </Typography>
                                    </StyledNicknameGuidelineHeader>
                                    <StyledNicknameGuidelineDivider />
                                    <StyledNicknameGuidelineHeaderDosAndDonts>
                                        <StyledNicknameGuidelineHeaderDosAndDontsItem>
                                            <Typography variant='small' color='emerald' weight='semiBold'>
                                                {`Do's`}
                                            </Typography>

                                            <StyledDosAndDontsItems>
                                                <img src={TickCircleIcon} alt='tick icon' aria-hidden='true' />
                                                <Typography variant='small' weight='medium'>
                                                    <span>Distinguish Entities:&nbsp;</span>
                                                    Name each entity uniquely,
                                                    like &quot;Quantum - Robotics&quot;
                                                    and &quot;Quantum - Aerospace.&quot;
                                                </Typography>
                                            </StyledDosAndDontsItems>
                                            <StyledDosAndDontsItems>
                                                <img src={TickCircleIcon} alt='tick icon' aria-hidden='true' />
                                                <Typography variant='small' weight='medium'>
                                                    <span>Avoid acronyms:&nbsp;</span>
                                                    Say &quot;Cloud Quest&quot; not &quot;CQ.&quot;
                                                </Typography>
                                            </StyledDosAndDontsItems>
                                        </StyledNicknameGuidelineHeaderDosAndDontsItem>
                                        <StyledNicknameGuidelineHeaderDosAndDontsItem>
                                            <Typography variant='small' color='error' weight='semiBold'>
                                                {`Don'ts`}
                                            </Typography>

                                            <StyledDosAndDontsItems>
                                                <img src={CloseCircleIcon} alt='tick icon' aria-hidden='true' />
                                                <Typography variant='small' weight='medium'>
                                                    <span>Use suffixes:&nbsp;</span>
                                                    Use &quot;TechGuru&quot; instead of &quot;TechGuru Pvt Ltd.&quot;
                                                </Typography>
                                            </StyledDosAndDontsItems>
                                            <StyledDosAndDontsItems>
                                                <img src={CloseCircleIcon} alt='tick icon' aria-hidden='true' />
                                                <Typography variant='small' weight='medium'>
                                                    <span>Exceed Character Limit:&nbsp;</span>
                                                    Keep it under 30 characters
                                                </Typography>
                                            </StyledDosAndDontsItems>
                                        </StyledNicknameGuidelineHeaderDosAndDontsItem>
                                    </StyledNicknameGuidelineHeaderDosAndDonts>
                                </StyledNicknameGuideline>
                            </Form.FormField>
                            <Form.FormField id='address' label='Registered Address'>
                                <Form.Input
                                    name="address"
                                    placeholder="Enter address"
                                    error={regdAddress.errorText}
                                    {...regdAddress}
                                />
                            </Form.FormField>
                            <Form.FormField id='gstin' label='GST Number' required={true}>
                                <Form.Input
                                    name="gstin"
                                    placeholder="Enter GST number"
                                    {...gstNumber}
                                    onChange={(e) => {
                                        e.target.value = e.target.value.toUpperCase();
                                        gstNumber.onChange(e);
                                    }}
                                    error={gstNumber.errorText}
                                />
                                {gstNumber.errorText && <StyledSpacer />}
                            </Form.FormField>
                            <Form.FormField id='pan' label='PAN Number' required={true}>
                                <Form.Input
                                    name="pan"
                                    placeholder="Enter PAN number"
                                    {...panNumber}
                                    onChange={(e) => {
                                        e.target.value = e.target.value.toUpperCase();
                                        panNumber.onChange(e);
                                    }}
                                    error={panNumber.errorText}
                                />
                                {panNumber.errorText && <StyledSpacer />}
                            </Form.FormField>
                            <Form.FormField id="salesforceId" label="Salesforce ID" required={true}>
                                <Form.Input
                                    name="salesforceId"
                                    placeholder="Enter Salesforce ID"
                                    {...salesforceId}
                                    onChange={(e) => {
                                        salesforceId.onChange(e);
                                    }}
                                    error={salesforceId.errorText}
                                />
                                {salesforceId.errorText && <StyledSpacer />}
                            </Form.FormField>
                        </StyledForm>
                    </StyledCompanyDetailsContainer>
                    <StyledImageUploadContainer>
                        <StyledUploadPillWrapper>
                            <UploadPill
                                onUpload={uploadLogo}
                                title={'COMPANY LOGO'}
                                subTitle={'(.png, .jpg, .jpeg)'}
                                formats={['.jpeg', '.jpg', '.png']}
                                errorMsg={companyLogoError}
                                maxSize={5}
                            >
                                {companyLogo?.file && companyLogo.url && (
                                    <>
                                        <StyledImage src={companyLogo.url} />{' '}
                                        <StyledImgRemContainer>
                                            <LabelButton
                                                onClick={() => setCompanyLogo(null)}
                                                color={theme.colors.error}
                                            >
                                                Remove
                                            </LabelButton>
                                        </StyledImgRemContainer>
                                    </>
                                )}
                            </UploadPill>
                            {!!companyLogoError.length && <FormError>{companyLogoError}</FormError>}
                        </StyledUploadPillWrapper>
                    </StyledImageUploadContainer>
                </StyledCardContainer>
            </Card>
            <Card>
                <h3>SPOC Details</h3>
                <StyledForm>
                    <Form.FormField id='spocName' label='Name'>
                        <Form.Input
                            name="spocName"
                            placeholder="Enter SPOC name"
                            error={spocName.errorText}
                            {...spocName}
                        />
                    </Form.FormField>
                    <Form.FormField id='phone' label='Phone'>
                        <Form.Input
                            name="phone"
                            placeholder="Enter SPOC phone number"
                            error={spocPhoneNumber.errorText}
                            {...spocPhoneNumber}
                        />
                    </Form.FormField>
                    <Form.FormField id='email' label='Email'>
                        <Form.Input
                            name="email"
                            placeholder="Enter SPOC email"
                            error={spocEmail.errorText}
                            {...spocEmail}
                        />
                    </Form.FormField>
                </StyledForm>
            </Card>
            {!!createCompanyProcess.error?.message.length && !createCompanyProcess.loading && !nickName.errorText && (
                <StyledErrorWrapper>
                    <ErrorContainer message={createCompanyProcess.error?.message} />
                </StyledErrorWrapper>
            )}
            {!!formError.length && !nickName.errorText && <FormError>{formError}</FormError>}
            <StyledConfimBtnWrapper>
                <Button type="primary" onClick={onCreateCompany} fullWidth>
                    {createCompanyProcess.loading ? <Loader inverted={false} /> : 'Create'}
                </Button>
            </StyledConfimBtnWrapper>
            <StyledSpacing />
            <StyledSpacing />
        </StyledContainer>
    );
};

export default AddCompany;
