import { Form, LoopButton, Modal, Toast, Typography } from '@loophealth/loop-ui-web-library';
import React, { useState, useMemo } from 'react';
import { updateWipeUserData } from '../../../adapters';
import { EmeraldCheckbox, EmeraldEmptyCheckboxIcon } from '../../../assets/img';
import useFormInput from '../../hooks/useFormInput';
import {
    StyledFormContainer,
    FormRow,
    FormColumn,
    CheckboxContainer,
    CheckboxLabel,
    StyledErrorWrapper
} from './styles';
import { IMemberDetailsUpdateModalProps, ICheckboxProps } from './types';
import { isMobileNumberValid, parseResponse } from '../../../utils/common';

const Checkbox: React.FC<ICheckboxProps> = ({
    onClick,
    isChecked,
    label
}) => {
    return (<CheckboxContainer onClick={onClick}>
        <img
            src={isChecked ? EmeraldCheckbox : EmeraldEmptyCheckboxIcon}
            alt={isChecked ? 'Checked' : 'Unchecked'}
        />
        <CheckboxLabel>
            <Typography variant="medium">{label}</Typography>
        </CheckboxLabel>
    </CheckboxContainer>);
};


const MemberDetailsUpdateModal: React.FC<IMemberDetailsUpdateModalProps> = ({
    isOpen,
    onOpenChange,
    initialValues,
    onSuccess
}) => {
    const mobileInput = useFormInput(initialValues.mobile);
    const emailInput = useFormInput(initialValues.email);
    const [wipeBackupPhone, setWipeBackupPhone] = useState(false);
    const [wipeBackupEmail, setWipeBackupEmail] = useState(false);
    const [mobileError, setMobileError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const toast = Toast.useToast();

    const hasChanges = useMemo(() => {
        return (
            emailInput.value !== initialValues.email ||
            mobileInput.value !== initialValues.mobile ||
            wipeBackupPhone ||
            wipeBackupEmail
        );
    }, [
        emailInput.value,
        initialValues.email,
        mobileInput.value,
        initialValues.mobile,
        wipeBackupPhone,
        wipeBackupEmail
    ]);

    const getButtonVariant = useMemo((): 'disabled' | 'filled' => {
        return hasChanges ? 'filled' : 'disabled';
    }, [hasChanges]);

    const handleMobileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        // Only allow digits and limit to 10 characters
        if (isMobileNumberValid(value)) {
            mobileInput.setValue(value);
            setMobileError('');
        }
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        emailInput.setValue(value);
        setEmailError('');
    };

    const toggleWipeBackupPhone = () => setWipeBackupPhone((prev) => !prev);
    const toggleWipeBackupEmail = () => setWipeBackupEmail((prev) => !prev);

    const handleSubmit = async () => {
        if (isLoading) return;


        setIsLoading(true);
        setEmailError('');
        setMobileError('');

        // Build request body with only changed fields
        const requestBody: Record<string, string | boolean> = {
            userId: initialValues.userId
        };

        if (emailInput.value !== initialValues.email) {
            requestBody.email = emailInput.value;
        }

        if (mobileInput.value !== initialValues.mobile) {
            requestBody.mobile = mobileInput.value;
        }

        if (wipeBackupPhone) {
            requestBody.wipeOutBackupPhoneNumber = true;
        }

        if (wipeBackupEmail) {
            requestBody.wipeOutBackupEmail = true;
        }

        try {
            const [err, response] = await parseResponse(updateWipeUserData(initialValues.userId, requestBody));
            if (err) {
                const errorData = JSON.parse(err.message);

                if (errorData?.email) {
                    setEmailError(response.email);
                }
                if (errorData?.mobile) {
                    setMobileError(response.mobile);
                }
                return;
            }

            if (response.status.toString().startsWith('2')) {
                toast?.success('Contact details updated successfully');
                setWipeBackupPhone(false);
                setWipeBackupEmail(false);
                if (onSuccess) onSuccess(response);
            }
        } catch (error: unknown) {
            toast?.error('Failed updating contact details');
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <Modal isVisible={isOpen} setIsVisible={onOpenChange} title="Edit Contact Details">
            <StyledFormContainer>
                <FormRow>
                    <FormColumn>
                        <StyledErrorWrapper error={!!mobileError || !!emailError}>
                            <Form.FormField id="mobile" label="Mobile">
                                <Form.Input
                                    placeholder="9999988888"
                                    onChange={handleMobileChange}
                                    value={mobileInput.value || ''}
                                    type="tel"
                                    error={mobileError}
                                />
                            </Form.FormField>
                        </StyledErrorWrapper>

                        <Checkbox isChecked={wipeBackupPhone} onClick={toggleWipeBackupPhone}
                            label='Wipe Backup Phone Number'></Checkbox>
                    </FormColumn>

                    <FormColumn>
                        <StyledErrorWrapper error={!!emailError || !!mobileError}>
                            <Form.FormField id="email" label="Email">
                                <Form.Input
                                    placeholder="example@email.com"
                                    onChange={handleEmailChange}
                                    value={emailInput.value || ''}
                                    type="email"
                                    error={emailError}
                                />
                            </Form.FormField>
                        </StyledErrorWrapper>
                        <Checkbox isChecked={wipeBackupEmail} onClick={toggleWipeBackupEmail}
                            label='Wipe Backup Email Address'></Checkbox>
                    </FormColumn>
                </FormRow>
            </StyledFormContainer>
            <LoopButton variant={getButtonVariant} onClick={handleSubmit} isLoading={isLoading}>
                Save
            </LoopButton>
        </Modal>
    );
};

export default MemberDetailsUpdateModal;
