import { ICompanyData } from '../../../../../redux/slices/CompanySlice/types';
import { IDropdownItem } from '@loophealth/loop-ui-web-library/dist/types/atoms/Form/DropDown/types';
import { IPolicyPlan } from '../../../../../redux/slices/PolicySlice/types';
import { getPolicyType } from '../../../../../utils/common';
import { DateFormatUtils } from '@loophealth/loop-ui-web-library';
import { IBulkUploadExcelData } from './types';
import { IMemberUpload } from '../../../../../redux/slices/ValidatorSlice/types';
import moment from 'moment';
import { formatGender, formatRelationship } from '../../../../../utils/constants/Endorsement/PossibleValue';
import { ADD_TEMPLATE_HEADERS, DELETE_TEMPLATE_HEADERS } from './constants';

export const prepareCompanyFilter = (companies: ICompanyData[]): IDropdownItem[] => {
    return companies?.map((company: any) => {
        return {
            name: company?.name,
            value: company?.id
        };
    });
};

export const preparePolicyFilter = (policies: IPolicyPlan[]): IDropdownItem[] => {
    const policyList = policies?.map((policy: any) => {
        const policyError = `(${policy.id + ') (Schema Error)'}`;
        const policyType = `(${
            getPolicyType(policy.policyType, policy.isTopUpPolicy, policy.isParentalPolicy) || 'N/A'
        })`;
        const policyId = `(${policy.id || 'N/A'})`;
        const policyNickName = `${policy.nickName ? '(' + policy.nickName + ')' : ''}`;
        const policyName = `${policy.insurerName} ${policyType} ${policyId} ${policyNickName}`;
        return {
            name: policy.error && policy.error.length ? policyError : policyName,
            value: policy?.id
        };
    });
    policyList.push({
        name: 'Not Applicable',
        value: 'Not Applicable'
    });
    return policyList;
};

export const validateExcelDate = (inputDate: string | undefined): Date | string => {
    if (!inputDate) return '';
    const dateFormatUtils = new DateFormatUtils();
    return dateFormatUtils.excelToBackendDateFormat(inputDate, true);
};

export const formatExcelDatesForBulkUpload = <T = Record<string, unknown>>(
    excelMemberData: IBulkUploadExcelData
): T => {
    return {
        userId: excelMemberData[ADD_TEMPLATE_HEADERS.userId]?.toString().trim() || '',
        employee_id: excelMemberData[ADD_TEMPLATE_HEADERS.employeeID]?.toString().trim() || '',
        relationship_to_account_holders: formatRelationship(excelMemberData[ADD_TEMPLATE_HEADERS.relationship]) || '',
        name: excelMemberData[ADD_TEMPLATE_HEADERS.name]?.toString().trim() || '',
        slabId: excelMemberData[ADD_TEMPLATE_HEADERS.slabId] || '',
        data_addition: '',
        mobile: excelMemberData[ADD_TEMPLATE_HEADERS.mobile]?.toString() || '',
        email_address: excelMemberData[ADD_TEMPLATE_HEADERS.email]?.toString() || '',
        company: '',
        gender: formatGender(excelMemberData[ADD_TEMPLATE_HEADERS.gender]),
        ctc: excelMemberData[ADD_TEMPLATE_HEADERS.ctc] || '',
        enrolment_due_date: validateExcelDate(excelMemberData[ADD_TEMPLATE_HEADERS.enrolmentDueDate]),
        date_of_birth: validateExcelDate(excelMemberData[ADD_TEMPLATE_HEADERS.dateOfBirth]),
        policy_start_date: validateExcelDate(excelMemberData[ADD_TEMPLATE_HEADERS.coverageStartDate]),
        policy_end_date: '',
        isException: (excelMemberData[ADD_TEMPLATE_HEADERS.exception] || '').toString().toLowerCase()
    } as T;
};

export const formatOffboardSheetForUpload = (excelMemberData: Record<string, string>): Record<string, unknown> => {
    return {
        userId: excelMemberData[DELETE_TEMPLATE_HEADERS.userId]?.toString() || '',
        relationship: formatRelationship(excelMemberData[DELETE_TEMPLATE_HEADERS.relationship]),
        dateOfLeaving: validateExcelDate(excelMemberData[DELETE_TEMPLATE_HEADERS.dateOfLeaving]),
        policyException: excelMemberData[DELETE_TEMPLATE_HEADERS.policyException]
    };
};

export const validateRegistrationData = (registrationData: Record<string, unknown>[]): Error | undefined => {
    if (registrationData?.length < 1) {
        return new Error('No member data found in the sheet');
    }
};

export const generateIdentifyMidTermRequest = (uploadedData: IMemberUpload | null): Record<string, unknown> => {
    const addrequest = uploadedData?.add.map((userData) => {
        return {
            employeeId: userData.employee_id,
            relationship: (userData.relationship_to_account_holders as string).toLowerCase(),
            coverageStartDate: userData.policy_start_date,
            name: userData.name
        };
    });

    const editrequest = uploadedData?.edit.map((userData) => {
        return {
            userId: userData.userId,
            relationship: (userData.relationship_to_account_holders as string).toLowerCase(),
            coverageStartDate: userData.policy_start_date,
            name: userData.name
        };
    });
    const addEditUsers = {
        add: addrequest,
        edit: editrequest
    };
    return addEditUsers;
};

export const generateIdentifyKycRequest = (uploadedData: IMemberUpload | null): Record<string, unknown[]> => {
    const editUsersData = uploadedData?.edit || [];
    const offBoardUserIds = uploadedData?.offboard
        .map((userData) => {
            return userData.userId;
        })
        .filter((userId) => userId) as string[];

    return {
        editUsersData,
        offBoardUserIds
    };
};

export const generateBackdatedAddData = (
    addMembers?: Record<string, unknown>[]
): Record<string, unknown>[] | undefined => {
    return addMembers?.map((member) => {
        const oldCoverageDate = moment(member.policy_start_date as string, 'DD/MM/YYYY').startOf('day');
        const todayDate = moment().startOf('day');
        if (todayDate.diff(oldCoverageDate, 'days') >= 45) {
            const newCoverageDate = todayDate.subtract(40, 'days').format('DD/MM/YYYY');
            return {
                ...member,
                policy_start_date: newCoverageDate,
                old_policy_start_date: member.policy_start_date
            };
        }
        return member;
    });
};
