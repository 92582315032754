import styled from 'styled-components';
import theme from '../../../theme';

export const StyledInitialValueCard = styled.div`
    padding: 12px 20px;
    background-color: ${theme.colors.planeGreenLight};
    border-radius: 8px;
`;

export const StyledFormContainer = styled.div`
    margin-bottom: 40px;
    width: 768px;
`;

export const FormRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 25px;
`;

export const FormColumn = styled.div`
    flex: 1;
`;

export const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 8px;
`;

export const CheckboxLabel = styled.span`
    margin-left: 8px;
`;

export const StyledErrorWrapper = styled.div<{ error: boolean }>`
    margin-bottom: ${({ error }) => error ? '80px' : '0'};
`;
