export enum ADD_TEMPLATE_HEADERS {
    userId = 'User ID',
    employeeID = 'Employee ID',
    relationship = 'Relationship to Account Holder',
    name = 'Name',
    slabId = 'Slab ID',
    mobile = 'Mobile',
    email = 'Email Address',
    gender = 'Gender',
    ctc = 'CTC',
    enrolmentDueDate = 'Enrolment Due Date (DD/MMM/YYYY)',
    dateOfBirth = 'Date of Birth (DD/MMM/YYYY)',
    coverageStartDate = 'Coverage Start Date (DD/MMM/YYYY)',
    exception = 'Exception'
}

export enum DELETE_TEMPLATE_HEADERS {
    userId = 'User ID',
    relationship = 'Relationship to Account Holder',
    dateOfLeaving = 'Date of Leaving (DD/MMM/YYYY)',
    policyException = 'Policy Exception'
}
