/* eslint-disable max-len */
import React from 'react';
import { deleteUserAPI, fetchUsersByParamAPI } from '../../../../../adapters';
import { SearchIcon } from '../../../../../assets/img';
import { IUserData } from '../../../../../adapters/types';
import useFormInput from '../../../../hooks/useFormInput';
import { Loader, PageHeader, RadioButton, Table, TableError, TableHeading, TableRow } from '../../../../atoms';
import { GlobalMemberDetailsRow, UserPoliciesModal } from '../../../../containers';
import {
    StyledContainer,
    StyledInputContainer,
    StyledRadioContainer,
    StyledSearchButton,
    StyledSearchContainer,
    StyledSearchError,
    StyledSearchInput,
    StyledSearchLabel,
    StyledSelectRow,
    StyledTableContainer
} from './styles';
import { useToast } from '../../../../hooks/useToast';

import { isSearchMemberByEmpIdFeatureEnabled } from '../../../../../utils/featureFlags';
import MemberDetailsUpdateModal from '../../../../containers/MemberDetailsUpdateModal';

const GlobalMemberSearch: React.FunctionComponent = () => {
    const [users, setUsers] = React.useState<IUserData[]>([]);
    // sets the userId for which we need to fetch the userPolicies
    const [uPoliciesToDisplay, setUPoliciesToDisplay] = React.useState('');
    const [uDetailsToUpdate, setUDetailsToUpdate] = React.useState<IUserData>();
    const [showUDetailsUpdateModal, toggleUDetailsUpdateModal] = React.useState(false);
    const [showUPolModal, setShowUPolModal] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [searchError, setSearchError] = React.useState('');
    const [type, setType] = React.useState<'email' | 'mobile' | 'employeeId'>('email');
    const searchText = useFormInput('');
    const toast = useToast();

    React.useEffect(() => {
        if (searchText.value.length) {
            searchText.finalValidationCheck();
        }
    }, [type]);

    const handleEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            setLoading(true);
            handleSearch();
        }
    };
    const showDeleteMemberModal = async (user: IUserData) => {
        const userId = user.userId;
        const name = user.firstName + ' ' + user.lastName;
        const msg = `
        Be Caustious!
        Clicking on OK will delete the Member. 
        Are you sure about deleting member - ${name}? 
        `;
        const deleteUser = window.confirm(msg);
        if (deleteUser) {
            const res: any = await deleteUserAPI(userId);
            if (res.status !== 200) {
                toast?.displayToast('error', 'Failed to Delete!', res.message.toString());
            } else {
                window.location.reload();
            }
        }
    };
    const handleSearchClick = () => {
        setLoading(true);
        handleSearch();
    };
    const handleSearch = async () => {
        if (!searchText.ref.current) return;
        if (!searchText.finalValidationCheck()) {
            setLoading(false);
            return;
        }
        const result = await fetchUsersByParamAPI(type, searchText.value, true);
        if (result?.length) {
            setSearchError('');
            setUsers(result);
            setLoading(false);
        } else {
            setUsers([]);
            setSearchError('No users found');
            setLoading(false);
        }
    };
    const interceptVisibility = (isVisible: boolean) => {
        if (!isVisible) {
            setUPoliciesToDisplay('');
        }
        setShowUPolModal(isVisible);
    };
    const showUserPoliciesModal = (userId: string) => {
        setUPoliciesToDisplay(userId);
        setShowUPolModal(true);
    };

    const handleMemberUpdateClick = (user: IUserData) => {
        toggleUDetailsUpdateModal(true);
        setUDetailsToUpdate(user);
    };
    const handleOnOpenChange = (state: boolean) => {
        toggleUDetailsUpdateModal(state);
        if (!state) setUDetailsToUpdate(undefined);
    };

    const handleOnSuccess = () => {
        handleSearchClick();
        toggleUDetailsUpdateModal(false);
    };
    return (
        <StyledContainer>
            <div>
                <PageHeader>Global Member Search</PageHeader>
            </div>
            <StyledInputContainer>
                <StyledSelectRow>
                    <StyledRadioContainer>
                        <RadioButton
                            name={'By Email address'}
                            value={type === 'email' ? true : false}
                            isSelected={true}
                            onClick={() => setType('email')}
                            disabled={false}
                        />
                    </StyledRadioContainer>
                    <StyledRadioContainer>
                        <RadioButton
                            name={'By Phone Number'}
                            value={type === 'mobile' ? true : false}
                            isSelected={true}
                            onClick={() => setType('mobile')}
                            disabled={false}
                        />
                    </StyledRadioContainer>
                    {isSearchMemberByEmpIdFeatureEnabled ? (
                        <StyledRadioContainer>
                            <RadioButton
                                name={'By Employee ID'}
                                value={type === 'employeeId' ? true : false}
                                isSelected={true}
                                onClick={() => setType('employeeId')}
                                disabled={false}
                            />
                        </StyledRadioContainer>
                    ) : null}
                </StyledSelectRow>
                <StyledSearchContainer>
                    <StyledSearchInput
                        {...searchText}
                        name={type}
                        placeholder={`Search by ${type === 'employeeId' ? 'Employee Id.' : type} ${
                            type === 'employeeId' ? `It's case sensitive.` : ''
                        }`}
                        onKeyDown={handleEnterKey}
                    />
                    <StyledSearchError>{searchText.errorText}</StyledSearchError>
                    <StyledSearchLabel>{type === 'email' ? '@' : type === 'mobile' ? '+91' : 'A1'}</StyledSearchLabel>
                    <StyledSearchButton onClick={handleSearchClick}>
                        <img src={SearchIcon} />
                    </StyledSearchButton>
                </StyledSearchContainer>
            </StyledInputContainer>
            <StyledTableContainer>
                {((users.length || searchError.length) && (
                    <Table>
                        <TableRow>
                            <TableHeading>#</TableHeading>
                            <TableHeading index>Name</TableHeading>
                            <TableHeading>Contact</TableHeading>
                            <TableHeading align="center">REL</TableHeading>
                            <TableHeading align="center">Gender</TableHeading>
                            <TableHeading align="center">DOB</TableHeading>
                            <TableHeading align="center">EmployeeId</TableHeading>
                            <TableHeading align="center">ParentID</TableHeading>
                            <TableHeading align="center">Status</TableHeading>
                            <TableHeading align="center">Actions</TableHeading>
                        </TableRow>
                        {(users || []).map((user, index) => (
                            <GlobalMemberDetailsRow
                                user={user}
                                key={index}
                                index={index}
                                deleteMember={showDeleteMemberModal}
                                showUserPolicies={showUserPoliciesModal}
                                onUpdateClick={() => handleMemberUpdateClick(user)}
                            />
                        )) || ''}
                        {uDetailsToUpdate && (
                            <MemberDetailsUpdateModal
                                isOpen={showUDetailsUpdateModal}
                                onOpenChange={handleOnOpenChange}
                                initialValues={uDetailsToUpdate}
                                onSuccess={handleOnSuccess}
                            />
                        )}
                        {(searchError.length && <TableError>{searchError}</TableError>) || <></>}
                    </Table>
                )) || <></>}
                {(loading && <Loader />) || <></>}
                <UserPoliciesModal
                    isVisible={showUPolModal}
                    setIsVisible={interceptVisibility}
                    userId={uPoliciesToDisplay}
                    companyId={''}
                />
            </StyledTableContainer>
        </StyledContainer>
    );
};

export default GlobalMemberSearch;
