import styled from 'styled-components';

export const StyledCapitalize = styled.div`
    text-transform: capitalize;
`;

export const StyledTableActionsContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

export const StyledSubTitle = styled.span(
    (p) => `
    white-space: no-wrap;
    color: ${p.theme.colors.muted};
`);

export const StyledStatusLabel = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

export const StyledDOBLabel = styled.span(
    (p) => `
    font-size: ${p.theme.fontSizes.label};
    color: ${p.theme.colors.muted};
    white-space: nowrap;
`);

export const StyledMargin = styled.span`
    margin-right: 15px;
`;

export const StyledTextWrapBreakId = styled.div`
    overflow-wrap: break-word;
    min-width: 90px;
`;

export const StyledBackupContainer = styled.div`
    display: flex;
    gap: 4px;
    align-items: start;
    flex-direction: column;
`;

export const StyledBackupCredContainer = styled.div`
    display: flex;
    align-items: start;
    justify-content: center;
    gap: 4px;
`;

export const StyledBackupCredIcon = styled.img`
    width: 14px;
    margin-top: 4px;
`;

export const StyledCredTexts = styled.div`
    display: flex;
    flex-direction: column;
`;
